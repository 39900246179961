// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dev-icons-languages {
  padding-left: 0;
  list-style: none;
  font-size: 2rem;
  margin-bottom: 0px;
  margin-top: 20px;
  text-align: right;
}

.software-skill-inline-languages {
  display: inline-flex;
  margin-right: 20px;
  margin-bottom: 0px;
  font-family: "Google Sans Regular";
}

#tooltip-top > .tooltip-inner {
  background-color: #fff;
  color: #000;
  border: 1px solid #062e56;
  border-radius: 5px;
  padding: 2.5px;
  margin-bottom: 2px;
}

#tooltip-top > .tooltip-arrow {
  border-top: 5px solid #062e56;
}

/* @media (max-width: 400px) {
  .dev-icons-languages {
    font-size: 25px;
  }
} */

@media (max-width: 768px) {
  .software-skill-inline-languages {
    margin-right: 10px;
    margin-bottom: 0px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/projectLanguages/ProjectLanguages.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,gBAAgB;EAChB,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB;AACnB;;AAEA;EACE,oBAAoB;EACpB,kBAAkB;EAClB,kBAAkB;EAClB,kCAAkC;AACpC;;AAEA;EACE,sBAAsB;EACtB,WAAW;EACX,yBAAyB;EACzB,kBAAkB;EAClB,cAAc;EACd,kBAAkB;AACpB;;AAEA;EACE,6BAA6B;AAC/B;;AAEA;;;;GAIG;;AAEH;EACE;IACE,kBAAkB;IAClB,kBAAkB;EACpB;AACF","sourcesContent":[".dev-icons-languages {\n  padding-left: 0;\n  list-style: none;\n  font-size: 2rem;\n  margin-bottom: 0px;\n  margin-top: 20px;\n  text-align: right;\n}\n\n.software-skill-inline-languages {\n  display: inline-flex;\n  margin-right: 20px;\n  margin-bottom: 0px;\n  font-family: \"Google Sans Regular\";\n}\n\n#tooltip-top > .tooltip-inner {\n  background-color: #fff;\n  color: #000;\n  border: 1px solid #062e56;\n  border-radius: 5px;\n  padding: 2.5px;\n  margin-bottom: 2px;\n}\n\n#tooltip-top > .tooltip-arrow {\n  border-top: 5px solid #062e56;\n}\n\n/* @media (max-width: 400px) {\n  .dev-icons-languages {\n    font-size: 25px;\n  }\n} */\n\n@media (max-width: 768px) {\n  .software-skill-inline-languages {\n    margin-right: 10px;\n    margin-bottom: 0px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
