// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.educations-header {
  font-size: 40px;
  line-height: 1.1;
  font-family: "Google Sans Regular";
  text-align: center;
}

@media (max-width: 1380px) {
  .educations-header {
    font-size: 35px;
  }
}

@media (max-width: 768px) {
  .educations-header {
    font-size: 30px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/containers/education/Educations.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,gBAAgB;EAChB,kCAAkC;EAClC,kBAAkB;AACpB;;AAEA;EACE;IACE,eAAe;EACjB;AACF;;AAEA;EACE;IACE,eAAe;EACjB;AACF","sourcesContent":[".educations-header {\n  font-size: 40px;\n  line-height: 1.1;\n  font-family: \"Google Sans Regular\";\n  text-align: center;\n}\n\n@media (max-width: 1380px) {\n  .educations-header {\n    font-size: 35px;\n  }\n}\n\n@media (max-width: 768px) {\n  .educations-header {\n    font-size: 30px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
