// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.experience-accord {
  margin: 50px;
  border-radius: 5px;
  background-color: #1d1d1d;
  border: 0.1px solid rgba(211, 211, 211, 0.397);
  transition: all 0.2s ease-in-out;
}

.experience-accord:hover {
  color: rgba(255, 255, 255, 1);
  box-shadow: 0 5px 15px #e2405f;
}

.accord-panel {
  font-family: "Google Sans Regular";
}

.accord {
  background-color: black;
}

@media (max-width: 768px) {
  .experience-accord {
    margin: 20px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/containers/experienceAccordion/ExperienceAccordion.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,kBAAkB;EAClB,yBAAyB;EACzB,8CAA8C;EAC9C,gCAAgC;AAClC;;AAEA;EACE,6BAA6B;EAC7B,8BAA8B;AAChC;;AAEA;EACE,kCAAkC;AACpC;;AAEA;EACE,uBAAuB;AACzB;;AAEA;EACE;IACE,YAAY;EACd;AACF","sourcesContent":[".experience-accord {\n  margin: 50px;\n  border-radius: 5px;\n  background-color: #1d1d1d;\n  border: 0.1px solid rgba(211, 211, 211, 0.397);\n  transition: all 0.2s ease-in-out;\n}\n\n.experience-accord:hover {\n  color: rgba(255, 255, 255, 1);\n  box-shadow: 0 5px 15px #e2405f;\n}\n\n.accord-panel {\n  font-family: \"Google Sans Regular\";\n}\n\n.accord {\n  background-color: black;\n}\n\n@media (max-width: 768px) {\n  .experience-accord {\n    margin: 20px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
